<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/valid-v-bind -->
<template>
  <v-row style="margin-left:50px; margin-top: 50px; justify-content: center;">
    <v-card width="95%" elevation="5" outlined style="border-radius:20px">
      <div style="margin-top:15px" />
      <v-data-table :search="search" :headers="headers" :items="users" :items-per-page="40">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Users</v-toolbar-title><br />
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
              hide-details></v-text-field>
          </v-toolbar>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="viewCard(item)">
            mdi-eye
          </v-icon>
        </template>

        <!-- eslint-disable-next-line -->

        <template v-slot:item.Gender="{ item }">
          {{ item.Gender === 1 ? 'Man' : item.Gender === 2 ? 'Woman' : 'Other' }}
        </template>


      </v-data-table>
    </v-card>

    <!--Dialog---->
    <v-dialog width="50%" v-model="dialog" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="dark">
          <v-btn icon dark @click="cleanData()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>User Id: {{ userData.Id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="!userData.Ban" dark text color="error" @click="disableUser()">
              Disable user
            </v-btn>
            <v-btn v-if="userData.Ban" dark text color="success" @click="enableUser()">
              Enable user
            </v-btn>
            <v-btn dark text @click="saveData()">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div style="padding: 20px">
          <!--General info-->
          <v-subheader>General info</v-subheader>

          <!-- main -->
          <div class="row" style="gap: 10px; padding: 10px;">
            <v-text-field label="Name" dense readonly disabled outlined v-model="userData.Name"></v-text-field>
            <v-text-field label="Surname" dense readonly disabled outlined v-model="userData.Surname"></v-text-field>
            <v-text-field label="Gender" dense readonly disabled outlined v-model="userData.Gender"
              type="number"></v-text-field>
          </div>
          <div v-if="userData.SportsStats && userData.SportsStats.PaddleTennis">
            <v-subheader>Padel Info</v-subheader>
            <div class="row" style="gap: 10px; padding: 10px;">
              <v-text-field label="Games Played" dense readonly disabled outlined
                v-model="userData.SportsStats.PaddleTennis.GamesPlayed"></v-text-field>
              <v-text-field label="Games Won" dense readonly disabled outlined
                v-model="userData.SportsStats.PaddleTennis.GamesWon"></v-text-field>
            </div>

            <div class="row" style="gap: 10px; padding: 10px;">
              <v-text-field label="NTRPCompleted" dense readonly disabled outlined
                v-model="userData.SportsStats.PaddleTennis.NTRPCompleted"></v-text-field>
              <v-text-field label="NTRP" dense outlined v-model="userData.SportsStats.PaddleTennis.NTRP"
                type="number"></v-text-field>
              <v-select :items="verifiedOptions" dense item-text="text" item-value="value" label="Rating Verified"
                outlined v-model="userData.SportsStats.PaddleTennis.IsRatingVerified"></v-select>
            </div>
          </div>

          <div v-if="userData.SportsStats && userData.SportsStats.Tennis">
            <v-subheader>Tennis Info</v-subheader>
            <div class="row" style="gap: 10px; padding: 10px;">
              <v-text-field label="Games Played" dense readonly disabled outlined
                v-model="userData.SportsStats.Tennis.GamesPlayed"></v-text-field>
              <v-text-field label="Games Won" dense readonly disabled outlined
                v-model="userData.SportsStats.Tennis.GamesWon"></v-text-field>
            </div>

            <div class="row" style="gap: 10px; padding: 10px;">
              <v-text-field label="NTRPCompleted" dense readonly disabled outlined
                v-model="userData.SportsStats.Tennis.NTRPCompleted"></v-text-field>
              <v-text-field label="NTRP" dense outlined v-model="userData.SportsStats.Tennis.NTRP"
                type="number"></v-text-field>
              <v-select :items="verifiedOptions" dense item-text="text" item-value="value" label="Rating Verified"
                outlined v-model="userData.SportsStats.Tennis.IsRatingVerified"></v-select>
            </div>
          </div>



          <v-divider></v-divider>
        </div>
      </v-card>
    </v-dialog>

  </v-row>
</template>
  
<script>
/* eslint-disable */

import { getUsers, updateUserData } from '../services/user-service'

export default {
  name: 'UsersTable',
  data: () => ({
    verifiedOptions: [
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ],
    userData: [],
    userDataCoachInfo: {},
    userDataTennis: {},
    userDataPaddleTennis: {},
    search: '',
    dialog: false,
    headers: [
      {
        width: "200px",
        text: "ID",
        align: "start",
        sortable: false,
        value: "Id",
      },
      {
        text: "Gender",
        align: "start",
        sortable: false,
        value: "Gender",
      },
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "Name",
      },
      {
        text: "Surname",
        align: "start",
        sortable: false,
        value: "Surname",
      },
      {
        text: "IsCoach",
        align: "start",
        sortable: false,
        value: "IsCoach",
      },
      {
        text: "Ban",
        align: "start",
        sortable: false,
        value: "Ban",
      },
      { text: 'Actions', value: 'actions' },
    ],
    users: [],
  }),
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getUsers().then((res) => {
        console.log(res)
        this.users = res
      })
    },
    viewCard(item) {
      console.log("item", item)
      this.userData = item
      if (!item.CoachInfo) this.userDataCoachInfo = {}; else this.userDataCoachInfo = item.CoachInfo
      let SportsStats = {}
      if (!item.SportsStats) SportsStats = {}; else SportsStats = item.SportsStats
      if (!SportsStats.Tennis) this.userDataTennis = {}; else this.userDataTennis = SportsStats.Tennis
      if (!SportsStats.PaddleTennis) this.userDataPaddleTennis = {}; else this.userDataPaddleTennis = SportsStats.PaddleTennis
      this.dialog = true
    },
    cleanData() {
      this.userData = {}
      this.userDataTennis = {}
      this.userDataPaddleTennis = {}
      this.userDataCoachInfo = {}
      this.dialog = false
      this.getData()
    },
    async saveData() {
      let newUserData = this.userData
      if (newUserData.SportsStats.Tennis.NTRP) {
        newUserData.SportsStats.Tennis.NTRP = parseFloat(newUserData.SportsStats.Tennis.NTRP)
      }
      if (newUserData.SportsStats.PaddleTennis.NTRP) {
        newUserData.SportsStats.PaddleTennis.NTRP = parseFloat(newUserData.SportsStats.PaddleTennis.NTRP)
      }
      await updateUserData(newUserData).then((res) => {
        console.log("res", res)
        this.cleanData()
      })
      this.cleanData()
    },
    async disableUser() {
      let newUserData = this.userData
      newUserData.Ban = true
      await updateUserData(newUserData).then((res) => {
        console.log("res", res)
        this.cleanData()
      })
    },
    async enableUser() {
      let newUserData = this.userData
      newUserData.Ban = false
      await updateUserData(newUserData).then((res) => {
        console.log("res", res)
        this.cleanData()
      })
    }
  }
};
</script>
