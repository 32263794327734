import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "@/firebase";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { getApp } from "firebase/app";
// import {TranslitToLat} from '@/services/translit-service'
// import {divideWord} from '@/services/utils'

let functions = getFunctions();
//emilator
const emulator = false;

if (emulator) {
  functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// Bulk updater
// async function bulkClubEdit(){
//   const clubs = await getGlubs()
//   console.log("clubs", clubs)
//   for (let index = 0; index < clubs.length; index++) {
//     const element = clubs[index];
//     let updatedObject = { ...element};

//     const translitName = TranslitToLat(updatedObject.Name)
//     const NewSearchSyllables = divideWord(translitName)
//     console.log("NewSearchSyllables", NewSearchSyllables)
//     updatedObject.SearchSyllables = NewSearchSyllables
//     await updateClub(updatedObject)
//     await delay(500); // Wait for 500 milliseconds
//     console.log("updatedObject", updatedObject.Name)
//   }
// }
// function delay(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

async function getGlubs() {
  let clubsList = [];
  const querySnapshot = await getDocs(collection(db, "clubs"));
  querySnapshot.forEach((doc) => {
    let club = doc.data();
    club.Id = doc.id;
    clubsList.push(club);
  });
  return clubsList;
}

// async function updateClub(ClubData) {
//     try {
//         const clubRef = doc(db, "clubs", ClubData.Id)
//         const updatedObject = { ...ClubData}
//         await updateDoc(clubRef, updatedObject);
//         return { status: "success" }
//     } catch (e) {
//         console.log(e)
//         return e
//     }
// }

async function updateClub(ClubData) {
  const updatedObject = { ...ClubData };
  const setData = httpsCallable(functions, "editCourt");
  const res = await setData(updatedObject).then((result) => {
    if (result.data) {
      return result.data;
    }
  });
  return res;

  // try {
  //     const clubRef = doc(db, "clubs", ClubData.Id)
  //     const updatedObject = { ...ClubData}
  //     await updateDoc(clubRef, updatedObject);
  //     return { status: "success" }
  // } catch (e) {
  //     console.log(e)
  //     return e
  // }
}

async function addClub(ClubData) {
  const updatedObject = { ...ClubData };
  const setData = httpsCallable(functions, "addNewCourt");
  const res = await setData(updatedObject).then((result) => {
    if (result.data) {
      return result.data;
    }
  });
  return res;
}

// async function addClub(ClubData) {
//     try {
//         const updatedObject = { ...ClubData}
//         const docRef = await addDoc(collection(db, "clubs"), {...updatedObject})
//         const docRefId = docRef.id
//         return { docRefId }
//     } catch (e) {
//         console.log(e)
//         return e
//     }
// }

async function uploadImage(file, Id) {
  try {
    let ImagesData = [];
    const storage = getStorage();
    const folderId = Id;
    console.log("folderId", folderId);
    // const folderPath = 'Images/Clubs/'+folderId
    //const deleteRef = ref(storage, folderPath);
    // Delete the file
    //deleteObject(deleteRef).then(() => {
    // File deleted successfully
    //}).catch((error) => {
    //console.log(error)
    //});

    for (let index = 0; index < file.length; index++) {
      const element = file[index];
      const path = "Images/Clubs/" + folderId + "/Image" + index;
      const storageRef = ref(storage, path);
      await uploadBytes(storageRef, element).then((snapshot) => {
        //console.log('Uploaded a blob or file!')
        console.log("snapshot: ", snapshot);
        let metaData = {};
        metaData.Path = snapshot.metadata.fullPath;
        metaData.Bucket = snapshot.metadata.bucket;
        metaData.Type = snapshot.metadata.contentType;
        metaData.Name = snapshot.metadata.name;
        ImagesData[index] = metaData;
        return { status: "success" };
      });
    }
    return { ImagesData };
  } catch (e) {
    console.log(e);
    return e;
  }
}

export {
  getGlubs,
  updateClub,
  addClub,
  uploadImage,
  // bulkClubEdit
};
